import $ from 'jquery';

window.jQuery = $;
require('slick-carousel');

export default {
  init() {
    // JavaScript to be fired on all pages

    // Nav: Toggle login modal
    const navActivate = $('#modal-login, #header-navigation .account');
    $('a[href="#login"], a[href="#register"], .modal-underlay').click(e => {
      e.preventDefault();
      navActivate.toggleClass('is-active');
    });

    // Event Promo Shortcode: Slick Carousel
    $('.module-promos').slick({
      fade: true,
      autoplay: true,
      autoplaySpeed: 8000,
      adaptiveHeight: true,
      arrows: false
    });

    // Modal: Initiate & Close
    let modalTarget = '';
    $('.modal-trigger, a[href="#pay"]').click((e) => {
      e.preventDefault();
      modalTarget = $(e.currentTarget).attr('href');
      $(modalTarget).addClass('is-active');
      navActivate.addClass('is-active');
    });
    $('.modal-close, .modal-underlay').click((e) => {
      e.preventDefault();
      $('.modal').removeClass('is-active');
      navActivate.removeClass('is-active');
    });

    // Modal: Find hash and then load if that hash is found in URL params
    $(window).on('load', () => {
      modalTarget = window.location.hash;
      if(modalTarget.length > 0) {
        $(modalTarget).addClass('is-active');
        navActivate.addClass('is-active');
      }
    });

    // Nav: More Toggle
    $('a[href="#nav-more"]').click((e) => {
      e.preventDefault();
      $('.nav-secondary').toggleClass('is-active');
      $(e.currentTarget).toggleClass('is-active');
    });

    // Nav: Login modal tabs and link initiation
    $('#login-tabs a, a[href="#login"], a[href="#register"]').click((e) => {
      e.preventDefault();
      const target = $(e.currentTarget).attr('href');
      if(target.includes('login')) {
        $('#edc-login').slideDown();
        $('#edc-register').slideUp();
        $('a[href="#tab-login"]').addClass('is-active');
        $('a[href="#tab-register"]').removeClass('is-active');
      } else if(target.includes('register')) {
        $('#edc-register').slideDown();
        $('#edc-login').slideUp();
        $('a[href="#tab-register"]').addClass('is-active');
        $('a[href="#tab-login"]').removeClass('is-active');
      }
    });

    // Clock: Initiate Clock
    clockUpdate();
    setInterval(clockUpdate, 30000);
    function clockUpdate() {
      const fetchTimezone = $('#header-clock time').data('timezone');
      let date = new Date();
      const dateDisplayOptions = {
        timeZone: fetchTimezone,
        weekday: 'long',
        month: 'long',
        day: 'numeric'
      }
      const timeDisplayOptions = {
        timeZone: fetchTimezone,
        hour: 'numeric',
        minute: '2-digit'
      }
      const timezoneDate = date.toLocaleDateString('en-US', dateDisplayOptions);
      $('#header-clock .date').text(timezoneDate);
      const timezoneTime = date.toLocaleString('en-US', timeDisplayOptions);
      $('#header-clock .time i').text(timezoneTime);
    }


    // Events Data Fields
    const eventData = [
      'disabled',
      'name',
      'rules',
      'status',
      'time',
      'timeShow',
      'tier',
      'players',
      'format',
      'bountyName',
      'bountyLink',
      'bountyAmount',
      'entry',
      'carryover',
      'day',
      'date',
      'updates',
      'buttons',
    ];

    const eventSidepots = [
      'pop_the_pot',
      'triple_shot'
    ];

    const eventPlayers = {
      'singles': 1,
      'doubles': 2,
      'trios': 3,
    }

    const eventModalTab = $('#event .heading-nav a');

    let ev = {};

    // Events Filter Players
    $('.nav-event-type input[type="radio"]').change(e => {
      const eventClass = $('.event');
      const playersFilterTarget = $(e.currentTarget).val();
      eventClass.removeClass('is-hidden');
      if(playersFilterTarget !== 'all') {
        eventClass.addClass('is-hidden');
        $(`.event-players--${playersFilterTarget}`).removeClass('is-hidden');
      }
    });

    // Pay Modal: Fetch transaction fees, output array
    function tfFind(format, value = 0) {
      const tfPer = [];
      const tfDol = [];
      const tfAll = [];
      $('.tf').each((i,e) => {
        const tfVal = atob($(e).val());
        const tfName = atob($(e).text());
        let tfDisp = '';
        let tfCalc = 0;
        if($(e).data('unit') === 'p') {
          tfPer.push(parseFloat(tfVal));
          tfDisp = ' (' + tfVal + '%)';
          tfCalc = (parseFloat(value) * (parseFloat(tfVal) / 100)).toLocaleString(
              'en-US', {
                style: 'currency',
                currency: 'USD'
              }
          );
        } else if($(e).data('unit') === 'd') {
          tfDol.push(parseFloat(tfVal));
          tfDisp = '';
          tfCalc = parseFloat(tfVal).toLocaleString(
              'en-US', {
                style: 'currency',
                currency: 'USD'
              }
          );
        }
        tfAll.push(`<li><span class="info">${tfName}${tfDisp}</small></span><span class="price">${tfCalc}</span></li>`);
      });

      if(format === 'per') {
        let perSum = 0;
        for(let i = 0; i < tfPer.length; i++) {
          perSum += tfPer[i];
        }
        return perSum;
      } else if(format === 'dol') {
        let dolSum = 0;
        for(let i = 0; i < tfDol.length; i++) {
          dolSum += tfDol[i];
        }
        return dolSum;
      } else if(format === 'list') {
        return '<ul class="payFees">' + tfAll.join('') + '</ul>';
      }
    }

    // Pay Modal: Function to run calculations on fees
    function tfCalc(inputField) {
      let payInput = $(inputField).val();

      if(payInput.includes('$')) {
        payInput = payInput.replace('$', '');
      }

      if(payInput.length === 0) {
        payInput = 0;
      }

      const payPerCalc = payInput * (tfFind('per', payInput) / 100);
      const payDolCalc = tfFind('dol', payInput);

      const payTotalAll = (+payInput + +payPerCalc + +payDolCalc).toLocaleString(
          'en-US', {
            style: 'currency',
            currency: 'USD'
          }
      );


      const payFeesList = tfFind('list', payInput);
      const payTotal = `<p class="payCalcTotal"><span class="info">Total</span><span class="price">${payTotalAll}</span></p>`;
      $('.modal-pay--fees').html(payFeesList + payTotal);

      const payPer = payInput * (tfFind('per') / 100);
      const payDol = tfFind('dol');
      $('.payProcessing input').val(+payPer + +payDol);
    }


    // Event Enter Form: Enable the Players in the grid
    function eventEntryPlayersEnable(ev) {
      const eventPlayersLint = ev['players'] !== undefined ? ev['players'].toLowerCase() : '';
      $('.event-player-single').addClass('is-disabled is-inactive');
      $('#event-player-1').removeClass('is-disabled is-inactive');
      $('#entry-review-players aside').removeClass('is-active');
      $('#entry-review-player-1').addClass('is-active');
      if(eventPlayersLint === 'doubles') {
        $('#event-player-1, #event-player-2').removeClass('is-inactive');
        $('#entry-review-player-1, #entry-review-player-2').addClass('is-active');
      } else if(eventPlayersLint === 'trios') {
        $('#event-player-1, #event-player-2, #event-player-3').removeClass('is-inactive');
        $('#entry-review-player-1, #entry-review-player-2, #entry-review-player-3').addClass('is-active');
      }
    }

    function eventSidepotPopulate() {
      // Input Sidepot Prices
      eventSidepots.forEach(eo => {
        const entryOption = $(`#sidepot-${eo}`).html();
        $(`[value*=${eo}] + label`).each((i,e) => {
          $(e).find('.entryValue-sidepot').html(entryOption);
        });
        $(`.entryValue-${eo} .yes`).html(entryOption);
      });
    }

    // Event Enter Form: Search through fields for Validation & Populate the Review section
    function eventPlayerFieldValidate(ev) {
      const ePhoneNumber = $('#event-enter .eventEntryPhoneCaptain input').val();
      const ePaypalEmail = $('#event-enter .eventEntryEmailPaypal input').val();
      const eventPlayers = ev['players'] === undefined ? '' : ev['players'].toLowerCase();
      const eP1nameFirst = $('#event-enter #event-player-1 .name_first input').val();
      const eP1nameLast = $('#event-enter #event-player-1 .name_last input').val();
      const eP1bullshooter = $('#event-enter #event-player-1 .eventEnter-player1Bullshooter input').val();
      const eP1options = $('#event-enter #event-player-1 input:checkbox:checked').map((i,e) => { return $(e).val(); }).get();
      const eP2nameFirst = $('#event-enter #event-player-2 .name_first input').val();
      const eP2nameLast = $('#event-enter #event-player-2 .name_last input').val();
      const eP2bullshooter = $('#event-enter #event-player-2 .eventEnter-player2Bullshooter input').val();
      const eP2options = $('#event-enter #event-player-2 input:checkbox:checked').map((i,e) => { return $(e).val(); }).get();
      const eP3nameFirst = $('#event-enter #event-player-3 .name_first input').val();
      const eP3nameLast = $('#event-enter #event-player-3 .name_last input').val();
      const eP3bullshooter = $('#event-enter #event-player-3 .eventEnter-player3Bullshooter input').val();
      const eP3options = $('#event-enter #event-player-3 input:checkbox:checked').map((i,e) => { return $(e).val(); }).get();

      // Validation
      const eP1required = [eP1nameFirst, eP1nameLast, eP1bullshooter, ePhoneNumber, ePaypalEmail];
      const eP2required = [eP2nameFirst, eP2nameLast, eP2bullshooter];
      const eP3required = [eP3nameFirst, eP3nameLast, eP3bullshooter];
      const eTermsAccept = $('.entryField-terms input[type="checkbox"]').is(':checked');
      let ePlayerRequirements = eP1required;
      if(eventPlayers === 'doubles') {
        ePlayerRequirements = $.merge(eP1required, eP2required);
      } else if(eventPlayers === 'trios') {
        ePlayerRequirements = $.merge(eP1required, $.merge(eP2required, eP3required));
      }
      if(ePlayerRequirements.indexOf('') === -1 && eTermsAccept) {
        $('#event-enter .gform_wrapper input[type="submit"]').prop('disabled', false);
      } else {
        $('#event-enter .gform_wrapper input[type="submit"]').prop('disabled', true);
      }

      // Entry Review Population
      for(let playerNum = 1; playerNum <= 3; playerNum++) {
        let currentPlayerOptions = [];
        let currentPlayerName = '';
        let currentPlayerBullshooter = '';
        if(playerNum === 1) {
          currentPlayerOptions = eP1options;
          currentPlayerName = `${eP1nameFirst} ${eP1nameLast}`;
          currentPlayerBullshooter = eP1bullshooter;
        } else if(playerNum === 2) {
          currentPlayerOptions = eP2options;
          currentPlayerName = `${eP2nameFirst} ${eP2nameLast}`;
          currentPlayerBullshooter = eP2bullshooter;
        } else if(playerNum === 3) {
          currentPlayerOptions = eP3options;
          currentPlayerName = `${eP3nameFirst} ${eP3nameLast}`;
          currentPlayerBullshooter = eP3bullshooter;
        }

        if(currentPlayerName !== 'undefined undefined') {
          if(currentPlayerName.length > 0 && currentPlayerBullshooter.length > 0) {
            $(`#entry-review-player-${playerNum}`).removeClass('is-invalid');
          } else {
            $(`#entry-review-player-${playerNum}`).addClass('is-invalid');
          }
        }

        $(`#entry-review-player-${playerNum} .playerData-name`).html(currentPlayerName);
        $(`#entry-review-player-${playerNum} .playerData-bullshooter`).html(currentPlayerBullshooter);
        const eventEntryRaw = ev['entry'] !== undefined ? ev['entry'].replace(/\D/g,''): 0;
        let currentPlayerPop = 0;
        let currentPlayerTriple = 0;
        let currentPlayerBounty = 0;
        let currentPlayerTotal = eventEntryRaw;
        if($.inArray(`p${playerNum}-pop_the_pot`, currentPlayerOptions) !== -1) {
          $(`#entry-review-player-${playerNum} .pop_the_pot .yes`).addClass('is-true');
          $(`#entry-review-player-${playerNum} .pop_the_pot .no`).removeClass('is-true');
          currentPlayerPop = $('.entryValue-pop_the_pot').html().replace(/\D/g,'').slice(0, -3);
        } else {
          $(`#entry-review-player-${playerNum} .pop_the_pot .yes`).removeClass('is-true');
          $(`#entry-review-player-${playerNum} .pop_the_pot .no`).addClass('is-true');
          currentPlayerPop = 0;
        }
        if($.inArray(`p${playerNum}-triple_shot`, currentPlayerOptions) !== -1) {
          $(`#entry-review-player-${playerNum} .triple_shot .yes`).addClass('is-true');
          $(`#entry-review-player-${playerNum} .triple_shot .no`).removeClass('is-true');
          currentPlayerTriple = $('.entryValue-triple_shot').html().replace(/\D/g,'').slice(0, -3);
        } else {
          $(`#entry-review-player-${playerNum} .triple_shot .yes`).removeClass('is-true');
          $(`#entry-review-player-${playerNum} .triple_shot .no`).addClass('is-true');
          currentPlayerTriple = 0;
        }
        if($('.entryField-bounty input').is(':checked')) {
          $('#entry-review-players .bounty .yes').addClass('is-true');
          $('#entry-review-players .bounty .no').removeClass('is-true');
          currentPlayerBounty = ev['bountyAmount'].replace(/\D/g,'');
        } else {
          $('#entry-review-players .bounty .yes').removeClass('is-true');
          $('#entry-review-players .bounty .no').addClass('is-true');
          currentPlayerBounty = 0;
        }
        currentPlayerTotal = ((+eventEntryRaw + +currentPlayerPop + +currentPlayerTriple + +currentPlayerBounty) / 100).toLocaleString(
            'en-US', {
              style: 'currency',
              currency: 'USD'
            }
        );
        $(`.entryCalc-player${playerNum}`).html(currentPlayerTotal);
      }
    }

    // Event Pay: Get bounty option & Amount
    function eventPayBounty(ev, ep = eventPlayers) {
      if($('.eventPay-bounty input').is(':checked')) {
        const payBountyAmount = parseFloat(ev['bountyAmount']);
        const payBountyPlayers = parseInt(ep[ev['players'].toLowerCase()]);
        const payBountyTotal = payBountyAmount * payBountyPlayers;
        const payBountyTotalDollar = payBountyTotal.toLocaleString(
            'en-US', {
              style: 'currency',
              currency: 'USD'
            }
        );
        $('.eventPay-bountyAmount input').val(payBountyTotal).trigger('change');
        $('.eventPay-reviewBounty .price').html(payBountyTotalDollar);
      } else {
        $('.eventPay-bountyAmount input').val(0).trigger('change');
        $('.eventPay-reviewBounty .price').html('$0.00');
      }
    }
    $(document).on('change', '.eventPay-bounty input', () => {
      eventPayBounty(ev);
      eventPayTotal();
    });

    // Event Pay: Populate the players & Data on the Pay Form
    let eventPay = {};
    function eventPayFormPopulate(ev) {
      $('.eventPay-info input').val(`${ev['players']} • ${ev['name']}, ${ev['rules']}, ${ev['format']}`);
      $('.eventPay-time input').val(`${ev['date']} • ${ev['timeShow']}`);

      const eventEntry = ev['entry'] !== undefined ? ev['entry'] : '$0';
      const eventBounty = ev['bountyAmount'] !== undefined ? ev['bountyAmount'] : '$0';

      eventPay['players'] = ev['players'] !== undefined ? eventPlayers[ev['players'].toLowerCase()] : 0;
      eventPay['entry'] = parseFloat(eventEntry.replace('$', ''));
      eventPay['bounty'] = parseFloat(eventBounty.replace('$', ''));
      eventPay['pop_the_pot'] = parseFloat(atob($('#sidepot-pop_the_pot').data('amt')));
      eventPay['triple_shot'] = parseFloat(atob($('#sidepot-triple_shot').data('amt')));

      $('.pay-pop_the_pot .sidepot').html(eventPay['pop_the_pot'].toLocaleString(
          'en-US', {
            style: 'currency',
            currency: 'USD'
          }
      ));
      $('.pay-triple_shot .sidepot').html(eventPay['triple_shot'].toLocaleString(
          'en-US', {
            style: 'currency',
            currency: 'USD'
          }
      ));

      $('.player, .eventPay-playerName').removeClass('is-active');
      for(let playerNum = 1; playerNum <= eventPay['players']; playerNum++) {
        $(`.player-${playerNum}, #event-player-${playerNum}`).addClass('is-active');
      }
    }

    // Event Pay: Calculate & Print event fees, show total
    const eventPayFields = [
      'eventPay-player1price',
      'eventPay-player2price',
      'eventPay-player3price',
      'eventPay-bountyAmount'
    ];
    function eventPayTotal(fields = eventPayFields) {
      let payItemsRaw = [];
      $.each(fields, (i,e) => {
        const payDataRaw = parseFloat($(`.${e} input`).val().replace('$', ''));
        payItemsRaw.push(payDataRaw);
      });
      const payItemsSum = payItemsRaw.reduce((partialSum, a) => partialSum + a, 0);
      const payItemsTrans = ((tfFind('per') / 100) * payItemsSum) + +tfFind('dol');
      const payItemsFeesDisplay = tfFind('list', payItemsSum);

      $('.payProcessing input').val(payItemsTrans).trigger('change');
      $('.eventPay-reviewTransaction').html(payItemsFeesDisplay);
      $('.eventPay-reviewTotal .price').text((+payItemsSum + +payItemsTrans).toLocaleString(
          'en-US', {
            style: 'currency',
            currency: 'USD'
          }
      ));
    }

    // Event Pay: Get the Checkboxes, calculate, and populate
    function eventPayPlayerOptions(ep) {
      let player1 = [];
      let player2 = [];
      let player3 = [];

      let player1options = [];
      let player2options = [];
      let player3options = [];

      const allOptions = $('#entry-pay-players input[type="checkbox"]');
      allOptions.each((i,e) => {
        if($(e).is(':checked')) {
          const optionId = $(e).attr('id');
          const optionType = optionId.slice(7);
          const optionName = $(e).parent('.player').siblings('.name').find('strong').text();
          if(optionId.includes('1')) {
            player1.push(ep[optionType])
            player1options.push(`${optionName} ($${ep[optionType]})`);
          }
          if(optionId.includes('2')) {
            player2.push(ep[optionType])
            player2options.push(`${optionName} ($${ep[optionType]})`);
          }
          if(optionId.includes('3')) {
            player3.push(ep[optionType])
            player3options.push(`${optionName} ($${ep[optionType]})`);
          }
        }
      });
      const playerItems = [
        player1options.join(', '),
        player2options.join(', '),
        player3options.join(', ')
      ];
      const playerSum = [
        player1.reduce((partialSum, a) => partialSum + a, 0),
        player2.reduce((partialSum, a) => partialSum + a, 0),
        player3.reduce((partialSum, a) => partialSum + a, 0)
      ]
      for(let playerNum = 1; playerNum <= 3; playerNum++) {
        const playerIndex = playerNum - 1;
        $(`.eventPay-player${playerNum}price input`).val(playerSum[playerIndex]).trigger('change');
        $(`.eventPay-player${playerNum}items input`).val(playerItems[playerIndex]);
        $(`.eventPay-player${playerNum}total .info strong`).text($(`.eventPay-player${playerNum}name input`).val());
        $(`.eventPay-player${playerNum}total .price`).text(playerSum[playerIndex].toLocaleString(
            'en-US', {
              style: 'currency',
              currency: 'USD'
            }
        ));
      }

      eventPayBounty(ev);
      eventPayTotal();
    }

    $(document).on('change keyup', '.player input', () => {
      eventPayPlayerOptions(eventPay);
    });

    if($('body').hasClass('post-type-archive-event-day') || $('body').hasClass('single-event-day')) {
      $(document).on('keyup', '#event-pay input', () => {
        eventPayPlayerOptions(eventPay);
      });
    }
    $(document).on('change keyup', '.player input', () => {
      eventPayPlayerOptions(eventPay);
    });
    $(document).on('gform_page_loaded', (event, formId, current_page) => {
      if($('body').hasClass('post-type-archive-event-day') || $('body').hasClass('single-event-day') || $('body').hasClass('single-championship')) {
        eventPayPlayerOptions(eventPay);
        eventPayFormPopulate(ev);
      }
    });

    // Event Modal: Populate Review Event Details
    function eventReviewDetails(ev) {
      eventData.forEach(d => {
        $(`.eventData-${d}`).html(ev[d]);
      });
    }

    // Event Modal: Open & Populate Both Forms and Info Tab
    $('.event *').on('click', e => {
      const clickTarget = $(e.currentTarget);

      if(clickTarget.parent('.event').hasClass('event-status--cancelled')) {
        return false;
      }

      eventData.forEach(d => {
        const find = clickTarget.parent('.event').next('.event-data').find(`.${d}`).html();
        $(`.eventModal-${d}`).html(find);
        ev[d] = find;
      });

      const eventEnterFormWrapper = $('#event-enter');
      if (ev['status'] !== 'Open' || ev['disabled'] === 'true') {
        eventEnterFormWrapper.addClass('is-disabled');
      } else {
        eventEnterFormWrapper.removeClass('is-disabled');
      }

      $('.entryField-eventTime input').val(ev['timeShow']);
      $('.entryField-eventDate input').val(ev['date']);
      $('.entryField-eventPlayers input').val(ev['players']);
      $('.entryField-eventName input').val(`${ev['name']} • ${ev['tier']}`);
      $('.entryField-eventDetails input').val(`${ev['format']}, ${ev['rules']}`);

      if(clickTarget.hasClass('event-button--enter')) {
        eventModalTab.removeClass('is-active');
        $('.event-modal-section').removeClass('is-active');
        $('#event .heading-nav a[href="#event-enter"], #event-enter').addClass('is-active');
      } else if(clickTarget.hasClass('event-button--pay')) {
        eventModalTab.removeClass('is-active');
        $('.event-modal-section').removeClass('is-active');
        $('#event .heading-nav a[href="#event-pay"], #event-pay').addClass('is-active');
      }

      // Player Toggling on Modal Load
      eventEntryPlayersEnable(ev);
      eventPlayerFieldValidate(ev);
      eventReviewDetails(ev);
      eventSidepotPopulate();
      eventPayFormPopulate(ev);
      eventPayBounty(ev);
      eventPayPlayerOptions(eventPay);
    });

    // Event Modal: Nav Tabs
    eventModalTab.click(e => {
      e.preventDefault();
      eventModalTab.removeClass('is-active');
      $(e.currentTarget).addClass('is-active');
      $('.event-modal-section').removeClass('is-active');
      const eventTabFind = $(e.currentTarget).attr('href');
      $(eventTabFind).addClass('is-active');
      eventPlayerFieldValidate(ev);
    });

    // Event Modal: Clean on Close
    $('#event .modal-underlay, #event .modal-close').click(() => {
      $('#event-enter').removeClass('is-disabled');
      eventModalTab.removeClass('is-active');
      $('.entryField-eventTime input, .entryField-eventDate input, .entryField-eventPlayers input, .entryField-eventName input, .entryField-eventDetails input, .eventPay-info input, .eventPay-time input, .payProcessing input').val('');
      $('.event-modal-section').removeClass('is-active');
      $('#event-info, #event .heading-nav a[href="#event-info"]').addClass('is-active');
      $('#entry-pay-review .price').text('$0.00');
      eventData.forEach(d => {
        $(`.eventModal-${d}`).empty();
      });
      $('#event-enter .gform_previous_button').trigger('click');
      $('#entry-pay-players input[type="checkbox"]').prop('checked', false);
      eventPlayerFieldValidate(ev);
    });

    // Event Modal: Combine and wrap player fields
    function eventPlayerWrapping() {
      for(let playerNum = 1; playerNum <= 3; playerNum++) {
        $(`.eventEnter-player${playerNum}`).wrapAll(`<div id="event-player-${playerNum}" class="event-player-single"></div>`);
      }
      $('.event-player-single').wrapAll('<div class="event-players"></div>');
    }
    eventPlayerWrapping();
    $(document).on('gform_page_loaded', (event, formId, current_page) => {
      if($('body').hasClass('post-type-archive-event-day') || $('body').hasClass('single-event-day') || $('body').hasClass('single-championship')) {
        eventPlayerWrapping();
        eventEntryPlayersEnable(ev);
        eventReviewDetails(ev);
      }
    });

    // Event Modal: Player Toggle
    $(document).on('click', '#event-enter .gfield--type-section', e => {
      const playerCurrent = $(e.currentTarget).parent('.event-player-single');
      if(!playerCurrent.hasClass('is-inactive')) {
        $('.event-player-single').addClass('is-disabled');
        $(e.currentTarget).parent('.event-player-single').removeClass('is-disabled');
        eventPlayerFieldValidate(ev);
      }
    });

    // Event Modal: Validate Fields on Typing
    $('#event-enter input').on('change keyup', () => {
      eventPlayerFieldValidate(ev);
    });

    // General Pay: Load the pay modal if there are existing values
    const payAmountInput = $('.payAmount input');
    const payNoteInput = $('.payNote input');
    const urlParams= new URLSearchParams(window.location.search);
    if(urlParams.has('pay')) {
      modalTarget = '#pay'
      $(modalTarget).addClass('is-active');
      navActivate.addClass('is-active');
      payAmountInput.val(urlParams.get('pay'));
      payAmountInput.trigger('keyup');
    }
    if(urlParams.has('payNote')) {
      payNoteInput.val(decodeURIComponent(urlParams.get('payNote')));
      payNoteInput.trigger('keyup');
    }

    // Championship Event: Populate Fields
    function csEventData(csEvent){
      return {
        'name':     $(csEvent.currentTarget).find('.name').text(),
        'players':  $(csEvent.currentTarget).find('.players').text(),
        'datetime': new Date($(csEvent.currentTarget).find('time').attr('datetime')),
        'handicap': $(csEvent.currentTarget).find('.handicap').text(),
        'rules':    $(csEvent.currentTarget).find('.rules').text(),
        'updates':  $(csEvent.currentTarget).find('.updates').html(),
        'links':    $(csEvent.currentTarget).find('.links').html(),
        'format':   $(csEvent.currentTarget).find('.format').text(),
        'attend':   atob($(csEvent.currentTarget).find('.attendance').data('atd')),
        'location': $(csEvent.currentTarget).find('.location').text(),
        'bounty':   parseFloat(atob($(csEvent.currentTarget).find('.bounty').data('amt'))),
        'entry':    parseFloat(atob($(csEvent.currentTarget).find('.entry').data('amt'))),
        'status':   atob($(csEvent.currentTarget).data('entry'))
      }
    }

    function csEventField() {
      return {
        'displayName':      $('.csEventData-name'),
        'displayDate':      $('.csEventData-date'),
        'displayLocation':  $('.csEventData-location'),
        'displayPlayers':   $('.csEventData-players'),
        'displayHandicap':  $('.csEventData-handicap'),
        'displayRules':     $('.csEventData-rules'),
        'displayFormat':    $('.csEventData-format'),
        'displayTime':      $('.csEventData-time'),
        'links':            $('.csEventData-links'),
        'updates':          $('.csEventData-updates'),
        'championship':     $('.csEventField-championship input'),
        'date':             $('.csEventField-date input'),
        'details':          $('.csEventField-details input'),
        'attend':           $('.csEventField-attend select'),
        'cost':             $('.csEventField-cost input'),
        'fees':             $('.csEventField-fees input'),
        'bounty':           $('.csEventField-bounty input')
      }
    }

    function csEventCalculate(csEvent) {

      let csTotal = 0;
      let csCost = csEventData(csEvent)['entry'];
      let csBounty = csEventData(csEvent)['bounty'];

      const csPlayers = csEventData(csEvent)['players'];
      if(csPlayers === 'Doubles') {
        csCost = csCost * 2;
        csBounty = csBounty * 2;
      } else if(csPlayers === 'Trios') {
        csCost = csCost * 3;
        csBounty = csBounty * 3;
      }


      if(csEventField()['bounty'].is(':checked')) {
        csTotal = csCost + csBounty
      } else {
        csTotal = csCost;
        csBounty = 0;
      }

      csEventField()['cost'].val(csTotal).trigger('change');

      csCost = csCost.toLocaleString(
          'en-US', {
            style: 'currency',
            currency: 'USD'
          }
      );

      csBounty = csBounty.toLocaleString(
          'en-US', {
            style: 'currency',
            currency: 'USD'
          }
      );
      $('.csEventCostEntry').text(csCost);
      $('.csEventCostBounty').text(csBounty);

      tfCalc('#championship .payAmount input');

      csEventField()['fees'].trigger('change');
    }

    function csEventPopulate(csEvent) {
      const csEventDateTime = csEventData(csEvent)['datetime'];
      const csEventDateDisplayOptions = {
        weekday: 'long',
        month: 'long',
        day: 'numeric'
      }
      const csEventTimeDisplayOptions = {
        hour: 'numeric',
        minute: '2-digit'
      }
      const csEventDate = csEventDateTime.toLocaleDateString('en-US', csEventDateDisplayOptions);
      const csEventTime = csEventDateTime.toLocaleString('en-US', csEventTimeDisplayOptions);

      csEventField()['date'].val(`${csEventDate} • ${csEventTime}`);
      csEventField()['championship'].val(csEventData(csEvent)['name']);
      csEventField()['details'].val(`${csEventData(csEvent)['players']} • ${csEventData(csEvent)['handicap']} • ${csEventData(csEvent)['format']} • ${csEventData(csEvent)['rules']}`);
      if(csEventData(csEvent)['attend'] !== 'any') {
        csEventField()['attend'].val(csEventData(csEvent)['attend']).prop('disabled', true).parent('.gfield--type-select').addClass('is-disabled');
        csEventField()['attend'].closest('.gfield--type-select').addClass('is-disabled');
      }

      csEventField()['displayName'].text(csEventData(csEvent)['name']);
      csEventField()['displayDate'].text(csEventDate);
      csEventField()['displayLocation'].html(csEventData(csEvent)['location']);
      csEventField()['displayPlayers'].text(csEventData(csEvent)['players']);
      csEventField()['displayHandicap'].text(csEventData(csEvent)['handicap']);
      csEventField()['displayRules'].text(csEventData(csEvent)['rules']);
      csEventField()['displayFormat'].text(csEventData(csEvent)['format']);
      csEventField()['displayTime'].text(csEventTime);
      csEventField()['updates'].html(csEventData(csEvent)['updates']);
      csEventField()['links'].html(csEventData(csEvent)['links']);

      const csPlayers = csEventData(csEvent)['players'];
      if(csPlayers === 'Doubles') {
        $('.player2').addClass('is-active')
        $('.player2 input').prop('disabled', false);
      } else if(csPlayers === 'Trios') {
        $('.player3, .player2').addClass('is-active');
        $('.player3 input, .player2 input').prop('disabled', false);
      }

      csEventCalculate(csEvent);

      $('.csEventDataBounty').text(csEventData(csEvent)['bounty'].toFixed(2));
      $('.csEventDataEntry').text(csEventData(csEvent)['entry'].toFixed(2));

    }

    function csEventClear() {
      $('#csEventClosed').removeClass('is-active');
      $('#championship form').show();
      csEventField()['championship'].val('');
      csEventField()['date'].val('');
      csEventField()['details'].val('');
      csEventField()['displayName'].text('');
      csEventField()['displayHandicap'].text('');
      csEventField()['displayRules'].text('');
      csEventField()['displayFormat'].text('');
      csEventField()['displayTime'].text('');
      csEventField()['displayDate'].text('');
      csEventField()['displayLocation'].html('');
      csEventField()['updates'].html('');
      csEventField()['links'].html('');
      csEventField()['cost'].val(0).trigger('change');
      csEventField()['fees'].val(0).trigger('change');
      csEventField()['attend'].val('').prop('disabled', false);
      csEventField()['attend'].closest('.gfield--type-select').removeClass('is-disabled');
      $('.player3, .player2').removeClass('is-active');
      $('.player input').not('.player1 input').removeClass('is-active').prop('disabled', true).val('');
      $('.csEventDataBounty').text('');
      $('.csEventDataEntry').text('');
      $('.csEventCostEntry').text('0.00');
    }

    let csEventActive = [];

    // Championship Event: Activate Modal
    $('.championship-event').on('click', (e) => {
      csEventActive = e;
      csEventClear();
      modalTarget = '#championship';
      $(modalTarget).addClass('is-active');
      navActivate.addClass('is-active');
      csEventPopulate(e);
      if(csEventData(e)['status'] !== 'open') {
        $('#championship form').hide();
        $('#csEventClosed').addClass('is-active');
      }
    });

    // Championship Event: Change Bounty
    $(document).on('change', '.csEventField-bounty input', () => {
      csEventCalculate(csEventActive);
    });

    // Pay Modal: Pull data into fees review
    $(document).on('change keyup keydown', '.payAmount input', () => {
      tfCalc('#modal-pay .payAmount input');
    });
    $(document).on('gform_page_loaded', (event, formId, current_page) => {
      tfCalc('#modal-pay .payAmount input');
      if($('body').hasClass('single-championship')) {
        csEventCalculate(csEventActive);
      }
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after init JS is fired

    $('#account-delete .delete-toggle').click(e => {
      e.preventDefault();
      $('.delete-toggle, .delete-form').toggleClass('is-active');
    });

    // Remove Loader on page load
    setTimeout(() => {
      $('#loader').remove();
    }, '3500');

    // Help Article Toggle on Category Archives
    $('.help-article h2.expand').click(e => {
      $(e.currentTarget).next('.content').slideToggle();
      $(e.currentTarget).toggleClass('is-active');
    });

    // Events Filter Toggle
    const eventsFilters = $('#events-filters');
    $('#events-filters input, #events-filters a, #events-filters header').on('click', (e) => {
      if(eventsFilters.hasClass('is-closed')) {
        e.preventDefault();
        eventsFilters.removeClass('is-closed');
      } else {
        eventsFilters.addClass('is-closed');
        if($(e.currentTarget).is('a')) {
          $(e.currentTarget).addClass('is-active');
        }
      }
    });
  },
};
